import React from "react";
import Logo from "../AGIO-LOGO-A.svg"; // Main logo path
import ArbitrumLogo from "../arbitrum_logo_fb8ebaca35.svg"; // Updated path if the SVG is in the src folder
import { Link } from "react-router-dom";

function Header(props) {
  const { address, isConnected, connect } = props;

  return (
    <header>
      <div className="leftH">
        <img src={Logo} alt="logo" className="logo" />
        <Link to="/" className="link">
          <div className="headerItem">Swap</div>
        </Link>
        <Link to="/tokens" className="link">
          <div className="headerItem">Redeem</div>
        </Link>
        <Link to="/LockInfo" className="link">
          <div className="headerItem">Your Financial Options</div>
        </Link>
        <Link to="/Analytics" className="link">
          <div className="headerItem">Analytics</div>
        </Link>
      </div>
      <div className="rightH">
        <div className="headerItem">
          <img src={ArbitrumLogo} alt="Arbitrum" className="eth" />
          Arbitrum
        </div>
        <div className="connectButton" onClick={connect}>
          {isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : "Connect"}
        </div>
      </div>
    </header>
  );
}

export default Header;
