import React, { useState, useEffect } from "react";
import { Select, message, Button } from "antd";
import { ethers } from "ethers";

const { Option } = Select;

function ClaimAUSD() {
  const [messageApi, contextHolder] = message.useMessage();
  const [tokenId, setTokenId] = useState(null);
  const [address, setAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [remainingLockTime, setRemainingLockTime] = useState(null);
  const [remainingExpirationTime, setRemainingExpirationTime] = useState(null);
  const [theoreticalAUSD, setTheoreticalAUSD] = useState(null);
  const [userNFTs, setUserNFTs] = useState([]);

  const contractAddress = '0xc8219947a017F10c9D9744c065cF736417Ad0b37'; // Updated contract address
  const contractABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_ausdToken",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "claimAUSD",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getRemainingLockPeriod",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getRemainingExpirationPeriod",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "lockInfo",
      "outputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "ethPriceAtLock",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "ethAmount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "lockTimestamp",
              "type": "uint256"
            }
          ],
          "internalType": "struct LockNFT.LockInfo",
          "name": "",
          "type": "tuple"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getEthPrice",
      "outputs": [
        {
          "internalType": "int256",
          "name": "",
          "type": "int256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "tokensOfOwner",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ];

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      provider.listAccounts().then((accounts) => {
        if (accounts.length > 0) {
          setAddress(accounts[0]);
          setIsConnected(true);
          fetchUserNFTs(accounts[0]);
        } else {
          connectWallet();
        }
      });
    } else {
      message.error('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  }, []);

  async function connectWallet() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        setAddress(userAddress);
        setIsConnected(true);
        message.success("MetaMask connected successfully!", 0);
        fetchUserNFTs(userAddress);
      } catch (error) {
        console.error("User denied account access:", error);
        message.error("User denied account access");
      }
    }
  }

  async function fetchUserNFTs(userAddress) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, contractABI, provider);
      const nfts = await contract.tokensOfOwner(userAddress);
      console.log("NFTs fetched: ", nfts);
      setUserNFTs(nfts.map(nft => nft.toString()));
    } catch (error) {
      console.error('Error fetching user NFTs:', error);
      message.error('Failed to fetch user NFTs.');
    }
  }

  async function fetchNFTDetails(tokenId) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, contractABI, provider);

    try {
      const remainingLock = await contract.getRemainingLockPeriod(tokenId);
      const remainingExp = await contract.getRemainingExpirationPeriod(tokenId);
      const lockInfo = await contract.lockInfo(tokenId);
      const currentEthPrice = await contract.getEthPrice();

      setRemainingLockTime(formatTime(remainingLock.toString()));
      setRemainingExpirationTime(formatTime(remainingExp.toString()));
      calculateTheoreticalAUSD(lockInfo, currentEthPrice);
    } catch (error) {
      console.error('Error fetching NFT details:', error);
      message.error('Failed to fetch NFT details.');
    }
  }

  function handleSelectNFT(value) {
    setTokenId(value);
    fetchNFTDetails(value);
  }

  async function calculateTheoreticalAUSD(lockInfo, currentEthPrice) {
    const ethPriceAtLock = ethers.BigNumber.from(lockInfo.ethPriceAtLock);
    const currentPrice = ethers.BigNumber.from(currentEthPrice);
    const amount = ethers.BigNumber.from(lockInfo.ethAmount);

    if (currentPrice.lt(ethPriceAtLock)) {
      const difference = ethPriceAtLock.sub(currentPrice);
      const ausdAmount = difference.mul(amount).div(ethers.BigNumber.from("100000000"));
      setTheoreticalAUSD(ethers.utils.formatEther(ausdAmount));
    } else {
      setTheoreticalAUSD("0.00");
    }
  }

  async function claimAUSD() {
    if (!tokenId) {
      message.error("Please select a valid NFT ID");
      return;
    }

    setIsClaiming(true);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      const transaction = await contract.claimAUSD(tokenId);
      await transaction.wait();

      message.success('AUSD claimed successfully!');
      // Remove claimed NFT from the list
      setUserNFTs(userNFTs.filter(nft => nft !== tokenId));
      setTokenId(null);
      setTheoreticalAUSD(null);
      setRemainingLockTime(null);
      setRemainingExpirationTime(null);
    } catch (error) {
      console.error('Error claiming AUSD:', error);
      message.error('Failed to claim AUSD. Claim countdown not over yet or your option is expired.');
    } finally {
      setIsClaiming(false);
    }
  }

  function formatTime(seconds) {
    seconds = parseInt(seconds, 10);
    const days = Math.floor(seconds / (3600 * 24));
    seconds %= 3600 * 24;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  return (
    <>
      {contextHolder}
      <div className="claimBox">
        {!isConnected && (
          <Button onClick={connectWallet}>Connect Wallet</Button>
        )}
        <div className="inputSection">
          <Select
            showSearch
            style={{ width: 200, marginBottom: 16 }}
            placeholder="Select NFT ID"
            optionFilterProp="children"
            onChange={handleSelectNFT}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={tokenId}
          >
            {userNFTs.map(id => (
              <Option key={id} value={id}>{id}</Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => claimAUSD()}
            disabled={!isConnected || !tokenId || isClaiming}
            loading={isClaiming}
          >
            Exercise your Option and claim AUSD
          </Button>
          {remainingLockTime && (
            <div>
              <p>Claim Countdown: {remainingLockTime}</p>
            </div>
          )}
          {remainingExpirationTime && (
            <div>
              <p>Expiration Countdown: {remainingExpirationTime}</p>
            </div>
          )}
          {theoreticalAUSD && (
            <div>
              <p>Claimable aUSD: {theoreticalAUSD} aUSD</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ClaimAUSD;
